import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from './message';
import AccountService from '../../services/account.service';

export const getDashboardListing = createAsyncThunk(
  "/add_bank/get_banks",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getDashboard();
        // console.log(response.all_banks)
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getBankList = createAsyncThunk(
  "/add_bank/get_banks",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getBankList();
        // console.log(response.all_banks)
      return response.all_banks;
    } catch (error) {
      return error;
    }
  }
)

export const getCountryList = createAsyncThunk(
  "/add_bank/country_list",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getCountries();
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const addBankDetails = createAsyncThunk(
  "/add_bank/",
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.addaccountDetails(payload);
      console.log("add bank response")
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error;
    }
  }
)

export const bvnVeirifcation = createAsyncThunk(
  "/verify_bvn/",
  async({bvn, dob}, thunkAPI) => {
    try {
      const payload = {bvn, dob}
      const response = await AccountService.verifyBvn(payload);
        console.log("BVN verification", response)
      return response;
    } catch (error) {
      return error;
    }
  }
)

/********    ACCOUNT  */

export const validateAccountNumber = createAsyncThunk(
  "/add_bank/validation/",
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.validateAccountNumber(payload);
        console.log("Account verification", response)
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getAccounts = createAsyncThunk(
  "/virtual_account/",
  async(thunkAPI) => {
    try {
      const response = await AccountService.getVirtualAccounts();
      console.log("accounts", response)
      return response.data;
    } catch (error) {
      return error;
    }
  }
)

export const createVirtualAccount = createAsyncThunk(
    '/virtual_account/',
    async(payload, thunkAPI) => {
        try {
            const response = await AccountService.createVirtualAccount(payload)
            if(response.success) {
              thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
            }
            else {
              thunkAPI.dispatch(setMessage({message: response.message, status: 'False'}))
            }
            return response
          } catch (error) {
            thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
            return error
        }
    }
)

/********    PAYCODES  */

export const createPaycode = createAsyncThunk(
  '/create_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.createPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const createBulkPaycode = createAsyncThunk(
  '/create_bulk_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.bulkCreatePaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const applyPayCode = createAsyncThunk(
  '/paycode/redeem/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const confirmPaycode = createAsyncThunk(
  '/confirm_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.confirmPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const claimPaycode = createAsyncThunk(
  '/claim_paycode/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.claimPaycode(payload)
          thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          console.log(response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const checkBalance = createAsyncThunk(
  '/paycode_balance/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.checkPaycodeBalance(payload)
          // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
          console.log("PAYCODE", response)
          return response
      } catch (error) {
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  }
)

export const getPaycodes = createAsyncThunk(
  '/paycodes/',
  async(payload, thunkAPI) => {
      try {
          const response = await AccountService.getMerchantPaycode(payload)
          // console.log("Paycodes", response)
          return response
      } catch (error) {
        return error
      }
  }
)

/********    TRANSACTIONS  */

export const getTransactionHistory = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.transactionHistory(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
}) 

export const getBillsPayments = createAsyncThunk(
  '/bills-payment/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getBills(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getP2pPayments = createAsyncThunk(
  '/p2p-payments/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getP2p(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getBusinesses = createAsyncThunk(
  '/merchants/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getMerchants(payload)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getBusinessById = createAsyncThunk(
  '/merchants/:id',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getMerchantById(payload)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getTransactionReports = createAsyncThunk(
  '/transaction_history/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.generateReport(payload)
      // console.log("transactions", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(thunkAPI) => {
      try {
        const response = await AccountService.getCharges()
        return response
      } catch (error) {
        return error
      }
  }
)

export const saveTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(payload, thunkAPI) => {
      try {
        const response = await AccountService.updateTransferCharges(payload)
        return response
      } catch (error) {
        return error
      }
  }
)

export const getMerchantTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(merchan_id, thunkAPI) => {
      try {
        const response = await AccountService.getMerchantCharges(merchan_id)
        return response
      } catch (error) {
        return error
      }
  }
)

export const getMerchantTransferChargesTypes = createAsyncThunk(
  '/transaction_name/',
  async(_, thunkAPI) => {
      try {
        const response = await AccountService.getChargesType()
        return response
      } catch (error) {
        return error
      }
  }
)

export const saveMerchantTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(payload, thunkAPI) => {
      try {
        const response = await AccountService.saveMerchantCharges(payload)
        return response
      } catch (error) {
        return error
      }
  }
)

export const updateMerchantTransferCharges = createAsyncThunk(
  '/transaction_charges/',
  async(payload, thunkAPI) => {
      try {
        const response = await AccountService.updateMerchantCharges(payload)
        return response
      } catch (error) {
        return error
      }
  }
)

export const getAnalytics = createAsyncThunk(
  '/transaction_charges/',
  async(params, thunkAPI) => {
      try {
        const response = await AccountService.analytics(params)
        return response
      } catch (error) {
        return error
      }
  }
)

export const getEarnings = createAsyncThunk(
  '/transaction/earnings/',
  async(params, thunkAPI) => {
      try {
        const response = await AccountService.getEarnings(params)
        return response
      } catch (error) {
        return error
      }
  }
)

/********    INVOICES  */

export const fetchInvoices = createAsyncThunk(
  '/merchant_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.getInvoices(payload)
      // console.log("Invoices", response)
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
})

export const getInvoice = createAsyncThunk(
  '/merchant_invoice/',
  async(reference, thunkAPI) => {
    try {
      const response = await AccountService.getInvoicesByRef(reference)
      return response
    }
    catch (error){ 
      return error
    }
})

export const addInvoice = createAsyncThunk(
  '/merchant_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.createInvoice(payload)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response
    }
    catch (error){ 
      thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
      return error
    }
  })

export const payInvoice = createAsyncThunk(
  '/pay_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.invoicePayment(payload)
      return response
    }
    catch (error){ 
      return error
    }
  })

export const invoiceVerification = createAsyncThunk(
  '/verify_invoice/',
  async(payload, thunkAPI) => {
    try {
      const response = await AccountService.verifyInvoice(payload)
      return response
    }
    catch (error){ 
      return error
    }
  })

export const getAnnouncements = createAsyncThunk(
  '/announcements/',
  async(thunkAPI) => {
    try {
      const response = await AccountService.announcements()
      console.log("Verify", response)
      return response
    }
    catch (error){ 
      return error
    }
  })
  
  /****** VOUCHERS */
  export const getUserVouchers = createAsyncThunk(
    '/merchant_invoice/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.getVouchers(payload)
        // console.log("Vouchers", response)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })
  
  /****** AUDIT TRAIL */
  export const getAudits = createAsyncThunk(
    '/paycodes/audit_trail/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.auditTrail(payload)
        console.log("Audit trail", response)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const lockUnlockPaycode = createAsyncThunk(
    '/paycodes/lock',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.managePaycode(payload)
        return response
      }
      catch (error){ 
        // thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })
  
  /****** Business status management */
  export const manageBusinessStatus = createAsyncThunk(
    '/merchants/manage/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.businessStatusManagement(payload)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })


  /****** Admin management */

  export const getAllAdmin = createAsyncThunk(
    '/settings/manage_admin/',
    async(_, thunkAPI) => {
      try {
        const response = await AccountService.getAdmins()
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const createAdmin = createAsyncThunk(
    '/settings/manage_admin/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.addAdmin(payload)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const removeAdmin = createAsyncThunk(
    '/settings/manage_admin/',
    async({email}, thunkAPI) => {
      try {
        const response = await AccountService.deleteAdmin(email)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const suspendAdmin = createAsyncThunk(
    '/settings/manage_admin/',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.deactivateAdmin(payload)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const getWithrawalLimitList = createAsyncThunk(
    '/merchants/withdrawal_limit',
    async(_, thunkAPI) => {
      try {
        const response = await AccountService.getWithrawalLimits()
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  export const manageWithdrawalLimit = createAsyncThunk(
    '/merchants/withdrawal_limit',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.manageWithrawalLimits(payload)
        return response
      }
      catch (error){ 
        thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
        return error
      }
  })

  // Email validation
  export const validateEmail = createAsyncThunk(
    '/merchants/validate_email',
    async(payload, thunkAPI) => {
      try {
        const response = await AccountService.emailValidation(payload)
        return response
      }
      catch (error){ 
        return error
      }
  })

    /******** LOYALTY VOUCHERS  */

  export const createLoyaltyPaycode = createAsyncThunk(
    '/create_paycode/',
    async(payload, thunkAPI) => {
        try {
            const response = await AccountService.createLoyaltyPaycode(payload)
            return response
        } catch (error) {
          return error
        }
    }
  )

  export const loyaltyPaycodeCharge = createAsyncThunk(
    '/set_charge/',
    async(payload, thunkAPI) => {
        try {
            const response = await AccountService.updateLoyaltyVoucherCharge(payload)
            return response
        } catch (error) {
          thunkAPI.dispatch(setMessage({message: error.message, status: 'False'}))
          return error
        }
    }
  )



  export const getLoyaltyPaycodeHistory = createAsyncThunk(
    '/paycodes/',
    async(payload, thunkAPI) => {
        try {
            const response = await AccountService.getLoyaltyPaycodeHistory(payload)
            return response
        } catch (error) {
          return error
        }
    }
  )

  export const getRates = createAsyncThunk(
    '/exchange_rate/',
    async(thunkAPI) => {
        try {
          const response = await AccountService.getExchangeRates()
          return response
        } catch (error) {
          return error
        }
    }
  )
  
  export const saveExchangeRate = createAsyncThunk(
    '/exchange_rate/',
    async(payload, thunkAPI) => {
        try {
          const response = await AccountService.updateExchangeRate(payload)
          return response
        } catch (error) {
          return error
        }
    }
  )
  
  export const getTransactionData = createAsyncThunk(
    '/transactions/analytics/',
    async(_, thunkAPI) => {
        try {
          const response = await AccountService.transactionData()
          return response
        } catch (error) {
          return error
        }
    }
  )

const accountSlice = createSlice({
  name: "account",
  initialState: {
    is_account_created: false,
    accounts: null
  },
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    setUser(state, action) {
      state.isLoading = false
      state.user = action.payload
    },
    setToken(state, action) {
      localStorage.setItem("token", action.payload); 
    },
    signOut(state) {
      state.isAuthenticated = false
      state.user = null
    },

  },
  extraReducers: {
    [createVirtualAccount.fulfilled]: (state, action) => {
    },
    [getAccounts.fulfilled]: (state, action) => {
      if(typeof(action.payload) === 'object')
      state.accounts = action.payload
    },
  }
})

const { reducer } = accountSlice
export default reducer;