import { Row, Col, Container, Card, Spinner, Button, Table, Badge, Dropdown, Modal } from "react-bootstrap";
import Layout from "../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../components/table/Paginator";
import { useEffect, useState } from "react";
import DrewsModal from "../components/modal/DrewsModal";
import { useDispatch } from "react-redux";
import { getTransactionData, getTransactionHistory } from "../redux/slices/account.service";
import formatCurrency from "../utils/currencyFormmter";
import Logo from "../components/Logo";
import dateFormatter from "../utils/dateFormatter";
import { useSearchParams } from "react-router-dom";
import RecordFilter from "../components/RecordFilter";

const InternationalCustomers = () => {


    const dispatch = useDispatch()

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const [searchParams, setSearchParams] = useSearchParams()
    const [activeTab, setActiveTab] = useState('all')
    const [modalShow, setModalShow] = useState(false)
    const [transaction, setTransaction] = useState({})
    const [transactions, setTransactions] = useState([])
    const [results, setResults] = useState([])
    const [analytics, setAnalytics] = useState({})
    const [receipt, setReceipt] = useState({})
    const [show_receipt, setShowReceipt] = useState(false)
    const [copiedText, setCopiedText] = useState('');
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({
        page: 1,
        merchant: '',
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: '',
    })
    const [seeMore, setSeeMore] =  useState(false)
    const [pageStatus, setPageStatus] = useState({isLoading: false, section: ''})
  

    // useEffect(() => {
    //     loadTransactions()
    //     loadAnalytics()
    // }, [payload.page, payload.status])

    const selectTransaction = (id) => {
        const transaction = transactions.filter(item => item.reference === id)[0]
        if(transaction.note == 'Electricity') {
            setReceipt(transaction);
            setShowReceipt(true)
        }
        else {
            setTransaction(transaction)
            setModalShow(true)
        }
    }

    const loadTransactions = () =>  {
        setLoading(true) 
        dispatch(getTransactionHistory(payload))
        .then((response) => {
            if(response.payload.results) {
                setTransactions(response.payload.results)
                setResults(response.payload.results)
            }

            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }
        })
        .finally(() => setLoading(false))
    }

    const loadAnalytics = () =>  {
        setPageStatus((prevState) => ({...prevState, isLoading: true, section: 'analytics'}))
        dispatch(getTransactionData())
        .then((response) => {
            if(response.payload?.data) {
                setAnalytics(response.payload.data)
            }
        })
        .finally(() => setPageStatus((prevState) => ({...prevState, isLoading: false, section: 'analytics'})))
    }

    const filterTransactions  = (value) => {
        setActiveTab(value)
        setPayload({...payload, status: value, page: 1})
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
          .then(() => {
            setCopiedText(copyText);
          })
          .catch(err => {
            console.error('Error copying text: ', err);
          });
    };

    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const handleSearch = () => {
        loadTransactions()
    }

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
    }

    const toggleTabs = (option) => {
        setActiveTab(option)
        if(option === 'all') {
            setPayload({...payload, status: ''})
        }
        else {
            setPayload({...payload, status: option})
        }
    }
 
    return (
        <Layout title="International Customers">
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <div className="text-center">
                                Available soon
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default InternationalCustomers;